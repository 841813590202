.container {
    height: 100vh;
    background: rgb(17, 64, 40);
    background: linear-gradient(90deg, rgba(17, 64, 40, 0.9822522759103641) 0%, rgba(43, 170, 104, 1) 29%, rgba(223, 246, 234, 1) 100%);

    @media screen and (min-width: 999px) {
        .login-box {
            width: 65%;
            height: 65%;
        }
    }


    .login-box {

        background-color: #fff;
        border-radius: 10px;
    }

        #left-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px 0px 0px 10px    
        }

    .credential-box {
        margin: 0% 20%;
        flex-direction: column;
    }
}

.credential-box .forgot-password {
    color: blue !important;
}

.email-box {
    width: 100%;
    margin-bottom: 4px;
    // height:75px
}

.auth-error-message {
    position: absolute;
    color: #e32222 !important;
    font-size: 12px;
    margin-top: -13px;
}


.password-box {
    position: relative !important;
    width: 100%;
    margin-bottom: 4px;

    .visibility {
        position: absolute !important;
        display: block;
        top: 15px !important;
        right: 12px !important;

        .visibility-icon {
            font-size: 20px;
        }
    }

    .visibility-off {
        position: absolute !important;
        display: block;
        top: 15px !important;
        right: 12px !important;
        .visibility-officon {
            font-size: 20px;
        }
    }
}

.hide-eye {
    display: none;
}