.no-data {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: calc(100% - 60px);
}

.noData-title {
    font-size: 16px;
    font-weight: 600!important;
    color: #74788d;
}

.noData-sub-title {
    font-size: 18px;
    color: #74788d;
}

.error-icon {
    color: #74788d;
    width: 100px;
}

.button-delete {
    border-radius: 50% !important;
    background-color: antiquewhite !important;
}
.button-delete .MuiSvgIcon-root {
    color: #d32f2f !important;
}
.loader-content {
    display: flex;
    height: 100% !important;
    justify-content: center;
    align-items: center;
}