#forgot-password {
    height:100%;

    .forgot-password-box {
        padding: 10px;
        width: 30%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 1px solid #c5c5c5 !important;
        border-radius: 10px !important;
        .form {
            // outline:2px solid greenyellow;
            width: 75% !important;

            .input-fields {
                width: 100% !important;
            }

            .buttons {
                width: 100% !important;
            }
        }
    }

    #email {
        width: 80px;
    }
}

@media screen and (min-width:900px) {
    .logo {
        margin-top:10px;
        width: 100px;
        height: 100px;
    }
}

@media screen and (min-width:300px) and (max-width:850px) {
    .logo {
        margin-top:10px;
        width: 72px;
        height: 72px;
    }
}

.logo {
    padding-top: 14px !important;
}

.email-adornment {
    margin-bottom: 10px !important;
}

.center .input-fields {
    width: 75% !important;
    position: relative;

    .email-error {
        position: absolute;
        top: 40px;
        left: 10px;
    }
}

.buttons {
    display: flex !important;
    justify-content: space-around !important;
    width: 75%;
    padding-top: 20px !important;
    margin-top: 10px;
    margin-bottom: 10px;

    .login-link {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .login-link {
            color: black !important;
        }
    }
}

.center {
    .login-link {
        text-decoration: none !important;
        color: blue !important;
    }
}