.grid {
    position: relative;
}
.grid .error {
    position: absolute;
    top:82px;
}
.grid .center {
    position: relative;
}
.grid .center .error {
    position: absolute;
    top:99px;
}
.grid .img-circle {
    font-size: 20px;
}
.img-circle {
    .image-logo {
        font-size: 20px !important;
    }
}
.grid .progress-content {
    display: flex;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
}

.notes-editor-wrapper {
    min-height: 10rem !important;
}