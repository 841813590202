.location-label{
    font-weight: 700;
    font-size: 18px;
    // line-height: 150%;
    color: #171717;
    // margin-bottom: 4px;
    background: none;
}

.address-location{
    color: #3D53F5 !important;
    // font-weight: 400;
}