#header {
      min-height: 38px;
      height: 38px;
    // overflow: auto;
    padding: 16px 0px;
    .search-box {
        border: 1px solid #CCCCCC;
        white-space: nowrap;
        border-radius: 5px;
        background-color: #F5F5F5;
        display: flex;
        align-items: center;
        width: 350px;
        height: calc(100% - 2px) !important;
       /* margin-right: 10px;*/

        .search-input {
            background: transparent;
            border: navajowhite;
            width: calc(100% - 30px);
            font-size: 0.9rem;

            &:focus {
                outline: none;
            }
        }

        .search-icon {
            padding: 3px 4px;
            width: 40px !important;
            height: 25px !important;
            color: gray;
        }
    }

    .filter-btn {
        padding: 8px 15px;
        border: 2px solid lightgray;
        color: gray;
        box-shadow: 0px 0px 0px 0px;

        &:hover {
            box-shadow: 0px 0px 0px 0px;
        }
    }

    .filter-btn-visible {
        padding: 8px 15px;
        border: 2px solid #118CFC;
        color: #118CFC;
        background-color: #118cfc33;
        box-shadow: 0px 0px 0px 0px;

        &:hover {
            background-color: #118cfc33;
            box-shadow: 0px 0px 0px 0px;
        }

    }
}

.add-btn {
    height: 100%;
}