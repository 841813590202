.picker-container {
    position: relative;
  //  width: 300px;
  }
   
  .emoji-icon {
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 10px;
  }
   
  .input-style {
   // padding: 7px 30px 7px 10px;
    width: calc(100% - 40px);
    border-radius: 3px;
  //  border: 1px solid #999;
    margin-bottom: 10px;
  }