.error-text {
    margin-top: 0px;
    margin-bottom: 0px;
    color:rgb(227, 34, 34) !important;
    font-size: 12px;
}
.success-text {
    margin-top: 0px;
    margin-bottom: 0px;
    color: #29995F;
    font-size: 12px;
}