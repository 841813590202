.favourite-location-container {
  padding: 10px;
  align-items: center;
  max-height:200px;
  margin-top: 20px;
  .favourite-location {
    min-width: 700px;
    height: 50px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 25px;
    gap:10px;
    .location-name {
      min-width: 400px;
    }
    .set-edit-location {
      cursor: pointer;
    }
  }
}

.grid-box{
    position: relative;

    .download-image {
      color: #5ed497;
      padding: 1rem;
      cursor: pointer;
    }
}

.error-message {
  color: #e32222!important;
  position: absolute;
  display: block;
  top:85px;
  font-size: 12px;
}
.success-message {
  color: rgb(14, 157, 14)!important;
  position: absolute;
  display: block;
  top:85px;
  font-size: 12px;
}

.image-error-message{
  color: #e32222 !important;
  font-size: 12px;
   position: absolute;
   top: 160px;
}