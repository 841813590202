.tags {
    .count-box {
        padding: 15px;
        width: 33.333%;
        border-right: 1px solid #dee2e6;
        cursor: pointer;
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .overview-tabs {
        border-radius: 10px;
    }

    .cards-title {
        color: #333333;
    }

    .analytics {
        color: #333333;
        font-weight: 600;
    }
}

.more-details {
    font-size: 16px;
    color: #226CF4;
   // color: black;
    cursor: pointer;
    font-size: 500;
    font-weight:600
}