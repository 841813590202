@media screen and (min-width: 1024px) {
    #profile-info {
        padding-top: 0px !important;
        width: 630px;
        height: 100%;
        border: 1px solid #dee2e6;
        // margin: 20%;
        padding: 20px;
        border-radius: 10px;
        margin: auto;
        margin-top: 50px !important
    }
}

.profile .center {
    margin-top: 40px;
    margin-bottom: 60px;
}

.profile-img {
    border-radius: 50%;
    object-fit: cover;
}

.admin-info-container{
    padding : 0px 16px ;
}
.grid-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    padding-top: 10px !important;
    padding-bottom: 10px !important;

    .label {
        margin-right: 10px;
        min-width: 250px;
    }
}