.detail-container {
  display: flex;
  flex-direction: column;
}

.essential-details {
  margin-top: 8px;
  //border:2px solid red;
  padding: 8px;
  .details-text {
    margin-top: 4px;
    // margin-left:15px;
  }
  .gridContainer {
    // margin-left:15px;
    margin-top: 15px;
    .grid {
      .grid-item {
        display: flex;
        margin-bottom: 24px;
        justify-content: space-between;
        padding-right: 16px;
        .grid-text {
          margin-right: 16px;
        }
      }
    }
  }
}

.detail-card {
  background-color: #fefefe;
  padding: 15px 0px;
  display: flex;
  gap: 20px;
  .user-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 120px;
    border: 1px solid #ededed;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 24px;
    .image {
      height: 120px;
      width: 120px;
      object-fit: cover;
    }
  }

  .user-detail {
    .text-wrap {
      word-wrap: break-word;
      white-space: normal !important;
      max-width: 600px;
    }
  }
}

// address {
//     font-style: normal;
//     line-height: inherit;
//     margin-bottom: 1rem;
// }

.trip-detail-card {
  background-color: #fefefe;

  // .trip-detail-heading {
  //     .trip-heading {
  //         font-size: 20px;
  //         font-weight: 600;
  //         // margin: 10px;
  //     }
  // }

  // .trip-details {
  //     // margin: 20px auto;
  //     padding: 16px 8px;
  //     margin-top: 0px;
  //     padding-top: 5px;
  //     text-align: left;
  // }

  // .field-value {
  //    // margin: 10px 0px 10px 10px;
  //     // width: 600px;
  //     // min-width: 600px;
  //     display: flex;
  //     justify-content: space-between;
  // }
}
