// left navbar
.left-side-nav {
  width: 200px;
  background-color: #F5F5F5;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  padding: 0rem 0.5rem;
  z-index: 1;
  font-family: "Space Grotesk";
  box-shadow: inset -3px -2px 8px #00000029;
  transition: width .2s linear;

  .nav-items-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 145px);
  }

  .collapse-ListItem {
    padding-left: 18px;
  }

  .listItem-text {
    font-weight: 400;
    font-size: .9rem;
    color: #666666;
  }

  .active-listItem-text {
    font-weight: 400;
    font-size: .9rem;
    color: #114028;
  }

  .navIcons {
    min-width: 40px;
  }

  .active-item {
    background-color: #BFEED5!important;
    border-radius: 5px;
  }

  #list-item {
    cursor: pointer;

    &:hover {
      border-radius: 5px;
      background-color: #fff;
    }
  }

  .logo-box {
    width: 100%;
    height: 135px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      // width: 60%;
      height: 60%;
      border-radius: 10px;
    }
  }

  .collapse-arrow {
    width: 45px;
    height: 37px;
    padding: 5px;
    display: flex;
    border-radius: 50%;
    cursor: pointer;
    align-items: center;
    justify-content: center;

    .collapse-arrow-icon{
      color: #0000008a;
    }


    &:hover {
      background-color: #BFEED5;

    }

    svg {
      padding-left: 7px;
    }
  }

  .divider-color {
    background-color: #999;
  }

  .margin-top-30 {
    margin-top: 10px;
  }

  .profile-transition{
    transition: "height .2s";
  }

  .profile {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    cursor: pointer;
    border-radius: 5px;
  }

  .active-profile {
    background-color: rgb(233, 233, 233);
  }

  .profile:hover {
    background-color: rgb(233, 233, 233);
  }

  .profile-buttons-hide {
    display: none;
  }

  .profileButtonWrap {
    display: flex;
    padding: 10px;
    cursor: pointer;
  }

  .profileButtonWrap:hover {
    background-color: rgb(233, 233, 233);
  }

  .profile-name-box{
    display: flex;
    align-items: center;
  }

  .profile-name-box .name{
    margin: 0px 30px;
  }

  .profile-avatar {
    width: 32px;
    height: 32px;
    color: #114028;
  }

  #logout-icon{
    padding-left: 18px;
  }

  .rotate-icon-down svg {
    transform: rotate(180deg);
    transition: .3s ease-in-out;
  }

  .rotate-icon-up svg {
    transform: rotate(0deg);
    transition: .3s ease-in-out;
  }
}

.right-side-content {
  transition: width .2s linear;
  margin-left: calc(200px + 16px);
  //overflow-y: hidden;
  padding: 0rem 1rem;

  .top-nav h5{
    margin: 20px 0px;
    text-transform: capitalize;
    font-weight: 600;
  }

}

/* For Chrome, Edge, and Safari */
::-webkit-scrollbar {
  width: 1px; /* width/height of the horizontal scrollbar */
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #bbb; /* color of the scrollbar thumb */
}

 
