.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.underline-none {
    text-decoration: none!important;
}

.start-tab-space {
    padding-left: 0px !important;
}

.tabs-space {
    text-transform: capitalize !important;
    font-weight: 600 !important;
}

.img-circle {
    border-radius: 50%;
    object-fit: cover;
    overflow: hidden;
    position: relative;
    cursor: pointer;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
    
   

    .edit-img {
        color: #fff;
        position: absolute;
        z-index: 15;
        bottom: 0;
        background-color: black;
        width: 100%;
        text-align: center;
        padding: 5px 0px;
        opacity: 0.7;

        &:hover {
            opacity: 0.9;
        }
    }
}


.pac-container { z-index: 100000 !important; }

.copy-icon {
    width: 13px !important;
}