.reset-password {
    height: 100% !important;

}

.main-container {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    border: 1px solid #c5c5c5 !important;
    border-radius: 10px;
    width: 25% !important;
    padding: 28px !important;
    margin-top: auto;
    margin-bottom: auto;
}

.main-container .set-password {
    display: flex;
    justify-content: center;
}

.main-container .form-details {
    width: 100% !important;
}

.main-container .textfield-adornment {
    width: 100% !important;
    margin-bottom: 5px;
    margin-top: 5px;
}

.textfield-adornment {
    position: relative;
}

.form-details .errorText {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.form-details .errorText {
    display: block;
    position: absolute;
    left: 6px;
    top: 48px;
}

.form-details .input-label {
    margin-bottom: 20px;
    padding-bottom: 10px;
}

.form-details .input-label {
    position: relative;
}

.input-label .visibility {
    position: absolute;
    display: block;
    right: 10px;
    top: 16.5px;

    .visibility-icon {
        font-size: 17px !important;
    }
}

.visibility:hover {
    cursor: pointer;
}

.visibility-off:hover {
    cursor: pointer;
}

.input-label .visibility-off {
    position: absolute;
    display: block;
    right: 10px;
    top: 16.5px;

    .visibility-officon {
        font-size: 17px !important;
    }
}

.input-label .icon-hidden {
    display: none;
}

.form-details .input-label .errorTextNone {
    display: none;
}
.textfield-adornment .errorTextPasswordNone {
    display: none;
}

.textfield-adornment .errorTextConfirmPassword {
    position: absolute;
    color: #e32222;
    top: 10px;
    display: block;
}

.textfield-adornment .errorTextConfirmPasswordNone {
    display: none;
}

.main-container {
    .typographical-text {
        padding-top: 25px;
        padding-left: 15px;
        padding-right: 15px;
        text-align: center;
    }
}

.ecopooling-text {
    color: #29995F;
    padding-bottom: 20px;
}

@media screen and (min-width:900px) {
    .ecopooling-text {
        font-size: 29px !important;
    }
}

@media screen and (min-width:400px) and (max-width:850px) {
    .ecopooling-text {
        font-size: 15px !important;
    }
}

@media screen and (max-width:1200px) {
    .ecopooling-text {
        font-size: 25px;
    }
}

@media screen and (width:820px) {
    .ecopooling-text {
        font-size: 16px !important;
    }
}
@media only screen and (max-width:600px) {
    .set-password {
        .password-button {
            font-size: small !important;
        }
    }
}

@media only screen and (min-width:601px) {
    .set-password {
        .password-button {
            font-size: small !important;
        }
    }
}

@media only screen and (max-width:1200px) {
    .set-password {
        .password-button {
            font-size: small !important;
        }
    }
}

.main-container .eco-pooling {

    margin-top: 10px;
}

@media only screen and (min-width:900px) {
    .main-container .eco-pooling {
        width: 100px;
        height: 100px;
    }
}

@media only screen and (min-width:400px) and (max-width:800px) {
    .main-container .eco-pooling {
        width: 60px;
        height: 60px;
    }
}

.reset-password {
    display: flex !important;
    justify-content: center !important;
}