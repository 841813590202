.image-container{
    flex-direction: column;
}

.admin-visibility {
    position: absolute !important;
    display: block;
    top: 42px !important;
    right: 8px !important;
}
.visibility-icon {
    font-size: 20px;
}
.admin-visibility-off {
    position: absolute !important;
    display: block;
    top: 42px !important;
    right: 8px !important;
}

.visibility-officon {
    font-size: 20px;
}

.hide-eye {
    display: none;
}