.user-detail-card {
    background-color: #fefefe;
    padding: 8px;
    .user-row {
        display: flex;
        //flex-wrap: wrap;
        // gap:20px;
        // margin-right: -15px;
        // margin-left: -15px;
    }
    .user-image-container{
        display: flex;
        align-items: center;
        justify-content:center ;
        width: 120px;
        height: 120px;
        border: 1px solid #ededed;
        border-radius: 50%;
        overflow: hidden;
        margin: auto 4px;
        .user-profile-image{
            height:120px;
            width: 120px;
            object-fit: cover;
            border-radius: 50%;
        }
    }
    .user-details{
        margin:16px 64px;
        .text-wrap {
            word-wrap: break-word;
            white-space: normal!important;
             max-width: 600px;
        }
    }
}




address {
    font-style: normal;
    line-height: inherit;
    // margin-bottom: 1rem;
}



.trip-detail-card{
        background-color: #fefefe;
        .trip-detail-heading{
            .trip-heading{
                // font-size: 20px;
                // font-weight: 600;
                padding: 8px;
                margin:0px;
                margin-bottom: 8px;
            }
        }
        .trip-details{
            // margin: 20px auto;
            margin-top: 0px;
            // padding-top: 5px;
            text-align: left;
            padding:8px;
            }
            .field-value{
               // margin: 10px auto;
                // width:600px;
                // min-width: 600px;
                display: flex;
                justify-content: space-between;
                padding:4px
            }
}
.eco-saving-parent-wrapper {
    .eco-saving-heading-and-filter-container{
       display: flex;
       justify-content: space-between;
       align-items: center; 
      // padding-right: 20px;
    //    padding-right: 210px;
    }
        .eco-saving-heading {
            padding: 8px;
            margin:0px;
            margin-bottom: 8px;
        }
  
        .eco-credits-container{
            padding:8px
        }
}






