#payouts-container {
  .header-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 1rem;
  
    .w-7 {
      min-width: 7rem;
    }
  }
  
}