.stripe-secret-visibility{
    position : absolute;
    top: 5px;
    right: 30px
}

.stripe-key-visibility {
    position: absolute !important;
    display: block;
    right : 25px;
    top: 190px;

    .visibility-icon {
        font-size: 20px;
    }
}

.stripe-key-visibility-off {
    position: absolute !important;
    display: block;
    right : 25px;
    top: 158px;
    .visibility-officon {
        font-size: 20px;
    }
}

.hide-eye {
    display: none;
}
