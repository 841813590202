.top-heading-container {
  padding: 8px;
  margin-bottom: 8px;
}
.driver-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  border: 1px solid #ededed;
  border-radius: 50%;
  overflow: hidden;
  margin: auto 4px;
  .driver-image {
    height: 120px;
    width: 120px;
    object-fit: cover;
    border-radius: 50%;
  }
}

.driver-detail-card {
  padding: 8px;
  .driver-row {
    display: flex;
  }
}

.driver-name-contact {
  margin-top: 16px;
}

.content-wrap {
  word-wrap: break-word;
  white-space: normal !important;
  margin-left: 64px;
  max-width: 600px;
}
.styled-horizontal-bar {
  margin-top: 16px;
  margin-bottom: 16px;
  border-top: 1px solid #0000001a;
}
.heading-container {
  padding: 8px 0px;
  margin-bottom: 8px;
}
.driver-trip-detail-container {
  // padding: 20px;
  .detail-row {
    display: flex;
    padding: 8px 0px;
    .left-element {
      width: 400px;
      margin-right: 32px;
    }
    .right-element {
      width: 400px;
      margin-left: 32px;
    }
  }
}
//  }
// .trip-detail-card{
//   .trip-detail-heading{
//     padding: 8px 16px;
//     margin-bottom: 10px;
//   }
//}

.passengers {
  display: flex;
  flex-wrap: wrap;
 // padding: 0px 20px;
  margin: 10px auto;
}

.margin {
  margin-top: 10px;
}

.no-passenger-message-box {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
