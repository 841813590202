.grid {
    position: relative;
}
.grid .error {
    position: absolute;
    top:82px;
    display: block;
}
.grid .hidden {
   visibility: hidden;
}
