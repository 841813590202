@media screen and (min-width: 1024px)  {
    #change-password {
        border: 1px solid #dee2e6;
        margin: 30%;
        padding: 24px;
        border-radius: 10px;
    }
}

#change-password {
    margin-top: 50px;
}
.grid-item {
    position: relative;
    .errorText {
        position: absolute;
        display: block;
        margin-top:5px;
        color: red
    }
    .hide-icon {
        display: none;
    }
    .eye-visibility {
        position:absolute;
        top:49px;
        right:10px;
        display: block;
        cursor: pointer;
    }
    .eye-visibilityOff {
        position:absolute;
        top:49px;
        right:10px;
        display:block;
        cursor:pointer;
    }
}