.text-center{
    text-align: center;
}

.cursor-pointer{
    cursor: pointer;
}

.border {
    border: 1px solid rgba(0, 0, 0, 0.23)!important;
    border-radius: 3px!important;
}

// Width
.w-30 {
 width: 30%!important;
}

.w-48 {
 width: 48%!important;
}

.w-50 {
 width: 50% !important;
}


// Height
.h-100 {
 height: 100%!important;
}

.h-75 {
 height: 75%!important;
}

.width-100 {
    width: 100% !important;
}

.h-90vh {
    height: 90vh !important;
}

// Weight
.w-100 {
 height: 100%!important;
}

.w-75 {
 height: 75%!important;
}

.cover {
    object-fit: cover !important;
}

// Margin 
.mx-1 {
    margin: 0rem 0.25rem!important;
}

.mx-2 {
    margin: 0rem 0.5rem!important;
}

.mx-3 {
    margin: 0rem 1rem!important;
}

.mx-4 {
    margin: 0rem 1.5rem!important;
}

.mx-5 {
    margin: 0rem 2rem!important;
}

.my-1 {
    margin: 0.25rem 0rem!important;
}

.my-2 {
    margin: 0.5rem 0rem!important;
}

.my-3 {
    margin: 1rem 0rem!important;
}

.my-4 {
    margin: 1.5rem 0rem!important;
}

.my-5 {
    margin: 2rem 0rem!important;
}

.mt-1 {
    margin-top: 0.25rem!important;
}

.mt-2 {
    margin-top: 0.5rem!important;
}

.mt-3 {
    margin-top: 1rem!important;
}

.mt-4 {
    margin-top: 1.5rem!important;
}

.mt-5 {
    margin-top: 2rem!important;
}

.mb-1 {
    margin-bottom: 0.25rem!important;
}

.mb-2 {
    margin-bottom: 0.5rem!important;
}

.mb-3 {
    margin-bottom: 1rem!important;
}

.mb-4 {
    margin-bottom: 1.5rem!important;
}

.mb-5 {
    margin-bottom: 2rem!important;
}

.mr-1 {
    margin-right: 0.25rem!important;
}

.mr-2 {
    margin-right: 0.5rem!important;
}

.mr-3 {
    margin-right: 1rem!important;
}

.mr-4 {
    margin-right: 1.5rem!important;
}

.mr-5 {
    margin-right: 2rem!important;
}

.ml-1 {
    margin-left: 0.25rem!important;
}

.ml-2 {
    margin-left: 0.5rem!important;
}

.ml-3 {
    margin-left: 1rem!important;
}

.ml-4 {
    margin-left: 1.5rem!important;
}

.ml-5 {
    margin-left: 2rem!important;
}

.m-1-auto {
    margin: 1rem auto !important;
}

// padding
.p-1 {
    padding: 1rem !important;
}

.p-2 {
    padding: 2rem !important;
}

.px-2 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
}

.d-flex{
    display: flex;
};

.flex-column {
    flex-direction: column !important;
}

.justify-center{
    justify-content: center;
}

.justify-end{
    justify-content: end;
}

.items-center {
    align-items: center;
}

.gap-1{
    gap: 1rem !important;
}

.gap-2{
    gap: 2rem !important;
}

.h-200 {
    height: 200px;
}

.br-20{
    border-radius: 20px !important;
}

.rounded-5 {
    border-radius: 5px !important;
}

.overflow-hidden {
    overflow: hidden !important;
}

.font-700 {
    font-weight: 700 !important;
}