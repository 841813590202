.fav-location-main-wrapper{
    
    .category-name-and-address{
        display: flex;
        flex-direction: column;
        padding: 0px 16px;
    }
    .border-bottom{
        border-bottom: 1px solid #ccc;
    }
   .schedule-wrapper{
    padding: 0px 16px 16px 16px;
    display: flex;
    //border-bottom: 1px solid #ccc;
    margin-bottom: 16px;
    .schedule-header{
        display: flex;
        flex-direction: column;
        width: 100%;
        // gap: 48px;
        .day-name{
            display: flex;
            padding: 8px 0px;
            .days-header{
            width:140px;
            font-weight: 800;
            }
            .days{
                font-weight: 800;
                width: 140px;
                text-align: center;
              }
         }
        .pick-up-container{
            display: flex;
            padding: 8px 0px;
            .pick-up-header{
                width:140px;
            }
            .pick-up{
                text-align: center;
                width: 140px;
            }
        }
        .drop-off-container{
            display: flex;
            padding: 8px 0px;
            .drop-off-header{
                width:140px;
            }
            .drop-off{
                text-align: center;
                width: 140px;
            }
        }
    }
    .schedule-day-and-dates{
        display: flex;
        flex-direction: column;
      .day{
        padding: 8px 0px;
        width:120px
      }
    }
   }
}