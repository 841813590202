.passenger-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .passenger-info {
    // border: 2px solid red;
    padding: 16px 0px;
    // width: 45%;
    display: flex;
    gap: 50px;
    width: 100%;

    //  border : 2px solid red;
    .passenger-image {
      height: 90px;
      width: 90px;
      border-radius: 50%;
    }


    .passenger-name-and-phone-number {
      // border: 2px solid blue;
      min-width: 170px;
      padding: 8px 0px
    }
  }

  .passenger-receipt-icon {
    cursor: pointer;
  }
}